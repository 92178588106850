document.addEventListener("DOMContentLoaded", () => {
	const menu = new Mmenu(".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		screenReader: {
			aria: true,
			text: true,
		},
	})

	const mobileMenuTrigger = document.querySelector( ".menu-button" )
	if ( mobileMenuTrigger ) {
		mobileMenuTrigger.addEventListener( "click", () => {
			if ($(".mobile-navigation-menu").hasClass("mm-opened")) {
				menu.API.close()
			}
			else {
				menu.API.open()
			}
		} )
	}

	if ( document.querySelector( "body" ).clientWidth <= 767 ) {
		$( ".inside-content-background" ).fitVids( { ignore: "nofit" } )
	}
} )
